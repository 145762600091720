import React, { Component } from 'react';
import "./Contact.css";
import Phonenumber from './Phonenumber';
import Hoburgsgubben from './Hoburgsgubben.jpg';

class Contact extends Component {
  render() {
    return (
      <div className="Content">
       
        <Phonenumber/>
        
        <div className="About-title">
            <h1>Kontakta oss</h1>
        </div>
      
        <div className="About-content">
            <h3>Telefon</h3>
            Vill du boka en taxiresa med oss gör du det enklast via <a href="Tel: 0498400800">telefon</a>
            <div className="About-conten">
                Då Gotland är stort förboka gärna. 
            </div>
            <div className="About-conten">
                Vi förbehåller oss rätten att köra på plock fredagar och lördagar 22:30 - 03:30.
                Gäller maj - augusti.
            </div>

            <h3>Mail</h3>
            <div className="Contact-mail">
                Skulle du vilja göra en bokning som ligger några dagar fram i 
                tiden kan du skicka en bokningsförfrågan via mail. Detta är framförallt 
                viktigt om du vill åka en längre sträcka på fastlandet till exempel.
                <br/>

                <div className="Contact-query">Maila oss då på följande mailadress.</div>
                <div className="Contact-mailadress">info@4taxi.se </div>

            </div>

            <h3>Företag</h3>
            <div classname="companies">Skulle du som företag vilja samarbeta med oss. Kontakta oss då på telefon eller mail.</div>
        </div>
        <div className="Picture-box">
            <div className="Contact-picture">
                <img src={Hoburgsgubben} alt="Vill du besöka Hoburgen på Gotland? Vi kör dig gärna dit."/>
            </div> 
            <div className="Picture-text">Vi kör dig gärna till Hoburgen, Lummelunda grottan,
             Kneippbyn eller till någon annan av de sevärdheter som finns här på Gotland.</div>
        </div>
      </div>
    );
  }
}

export default Contact;
