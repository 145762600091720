
import React from "react";
import "./Navbar.css";
/*import Navbar from "./components/Navbar";*/
import {
    BrowserRouter as Router,
    Routes,
    Route,
    NavLink,
} from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Boxes from "./Boxes";
import Taxi from "./Taxi";
import Contact from "./Contact";


function Navbar() {
    return (
    
        <Router>
           
             <ul>
                        <li>
                            <NavLink to="/" exact activeStyle={{color:'green'}}>Hem</NavLink>
                        </li>
                        <li>
                            <NavLink to="/taxi" exact activeStyle={{color:'green'}}>Taxi</NavLink>
                        </li>
                        <li>
                            <NavLink to="/boxes" exact activeStyle={{color:'green'}}>Bud</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" exact activeStyle={{color:'green'}}>Om oss</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact" exact activeStyle={{color:'green'}}>Kontakt</NavLink>
                        </li>
                    </ul>    
                
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/taxi" element={<Taxi />} />
                <Route path="/boxes" element={<Boxes />}/>
                <Route  path="/about"  element={<About />}/>
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </Router>
        
      );

}

export default Navbar;