import React, { Component } from 'react';
import './Boxes.css';
import Kartong from './kartong.jpg';
import Phonenumber from './Phonenumber';

class Boxes extends Component {
  render() {
    return (
      <div>
         <Phonenumber/>
        <div className="Boxes"> 
          <h1 className="About-title">Budkörningar</h1> 
          <h2 className="Box-undertitle">Har du leveranser som snabbt behöver levereras?</h2>
       
          <div className="Boxes-content">Vi levererar gärna dina paket, hämtar din pizza eller om du 
            har andra saker som du vill få levererade.</div>
       
          <h3 className="Box-undertitle">Är du företagare och har mindre leveranser?</h3>
          <div className="Boxes-business_content">Har du regelbundna leveranser av mindre volym och vikt?</div>
          <div className="Boxes-business_content">Vi hjälper dig gärna. Kontakta oss så kommer vi överrens om något som passar oss båda.</div>
        
        <div clasName="Boxes-business_phone"></div>
        <h3>Telefon</h3>
            Vill du boka en taxiresa med oss gör du det enklast via telefon
            <div className="Contact-phone">
                <a href="Tel:0498-400800">0498-400800</a>
            </div>

            <h3>Mail</h3>
            <div className="Contact-mail">
                <br/>

                <div className="Contact-query">Maila oss då på följande mailadress.</div>
                <div className="Contact-mailadress">info@4taxi.se </div>

            </div>
          </div> 
          <div className="Contact-picture">
            <img src={Kartong} alt="Vi kör över hela Gotland."/>
          </div>
      </div>
     
    );
  }
}

export default Boxes;