import React, { Component } from 'react';
import './TaxiServices.css';
import Kartong from './kartong.png';
import Bussen from './bussenstor.jpeg';


class TaxiServices extends Component {
  render() {
    return (
      <div className="TaxiServices">
        <h1 className="TaxiService_title">Vårt erbjudande</h1>
    
        <div className="TaxiServices_box">
          <div className="TaxiServices_innerbox">
            <div className="TaxiServices_picturebox">
              <div className="TaxiServices_taxi">
              <div className="TaxiServices_image-one">
                <img className="TaxiServices_image" src={Bussen} alt="Vi kör dig över hela Sverige. Ring så lämnar vi en prisuppgift."/>
              </div>  
                <div className="TaxiServices_text-one">
                  <h2 className="TaxiServices_text-title">Taxi, vi kör över hela ön</h2>
                  <div className="TaxiServices_text-content"> 4:ans taxi har sitt kontor i Linde, Hemse och kan köra dig över hela ön. Skulle du vilja åka någon annanstans är det bara att ringa så löser vi det. </div>
                  <div className="TaxiService_phone">
                    Ring <a href="Tel: 0498400800">0498-400 800</a>
                  </div>
                </div>
              </div>
              <div className="TaxiServices_taxi">
           
                <div className="TaxiServices_text-two">
                  <h2 className="TaxiServices_text-title">Transport av gods</h2>
                  <div className="TaxiServices_text-content">Har ni paket eller andra saker som behöver levereras, även akut. Ring oss så sköter vi leveransen åt er. </div>
                  <div className="TaxiService_phone">
                  Ring <a href="Tel: 0498400800">0498-400 800</a>
                  </div>
                </div>
                <div className="TaxiServices_image-two">
                <img className="TaxiServices_image" src={Kartong} alt="Vi levererar dina varor. Ring oss på 0498-400800."/>
              </div>
              </div>
            </div> 
          </div>  
        </div>
      </div>
    );
  }
}

export default TaxiServices;