import React, { Component } from 'react';
import './Header.css';
import Logga from './Loggaliten.jpg';


class Header extends Component {
  render() {
    return (
      <div className="Header">
      
          <div className="Header_picture">
          <img src={Logga} alt="4:ans taxi Gotland"></img>
          </div> 
      
        <div className="Header-box">
          <div className="Header-text">
            4:ANS TAXI
          </div>
       
          <div className="Header-number">
            0498 400 800
          </div>
        </div> 
       
      
       
      </div>
    );
  }
}

export default Header;