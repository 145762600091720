import React, { Component } from 'react';
import './Taxi.css';
import Hemse from './hemse.jpg';
import Phonenumber from './Phonenumber.js';

class Taxi extends Component {
  render() {
    return (
      <div className="Content">
        <Phonenumber/>
      
     
            <div className="Taxi-content">4:ans taxi kör dig dit du önskar. Gotland, fastlandet, Tyskland eller 
            vart du vill. Kontakta oss så kommer vi överrens om någon som fungerar för oss båda.</div>
          <div className="Contact-picture">
            <img src={Hemse} alt="Boka din taxiresa med 4:ans Taxi."/>
          </div> 
         

        
      </div>
    );
  }
}

export default Taxi;