import React, { Component } from 'react';
import './Phonenumber.css';


class Phonenumber extends Component {
  render() {
    return (
        <div className="Phonenumber-border">
             <h2 className="Phonenumber-undertitle">Vi tar dig dit du vill!</h2>
      
        </div>
    );
    }
}

export default Phonenumber;

//    <div className="Phonenumber-prompt">Ring oss på</div>
//<div className="Phonenumber-phonenumber"><a href="Tel: 0498400800">0498-400 800</a></div>
       