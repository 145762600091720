import React, { Component } from 'react';
import './Footer.css';


class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="Footer-box">
          <div className="Footer-innerbox">
          <div className="Footer-innerbox_one">
            <div className="Footer-adress">
            <div className="Footer-adress_name">XL Fastigheter och Redovisning AB</div>
            <div className="Footer-adress_address">Linde Rangsarve 312</div>
            <div className="Footer-adress_city">623 57 Hemse</div>
            </div>
           </div> 
          
          <div className="Footer-innerbox_two">
          <div className="Footer-innerbox-right">
          <div className="Footer-email">Tel: 0498-400 800</div>
          <div className="Footer-email">Email: info@4taxi.se</div>
            <div className="Footer-orgnumber">
                <div className="Footer-orgnumber_text">Org-nr: 
                <span className="Footer-orgnumber_number"> 559418-3104</span></div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

 /*Momsregistreringsnummer: SE559418310401*/