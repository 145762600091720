import React, { Component } from 'react';
import './About.css';
import Visby from './Visby.jpg';
import Phonenumber from './Phonenumber';

class About extends Component {
  render() {
    return (
      <div className="Content">
        <div className="Content-pictureBox">
          <div className="Content-picture">
            <img src={Visby} alt="Vi kör över hela Gotland."/>
          </div>  
          </div>
          <Phonenumber/>
          <div className="About-title">
            <h1 >Om 4:ans taxi</h1> 
          </div>
      
        <div className="About-content">
            <div className="About-content_text">
              Tidigt i höstas startade jag taxibolaget 4:ans Taxi där en av mina närmaste vänner är chaufför.</div>
            <div className="About-content_text">
              På grund av omständigheter som vi inte kunde rå för har vi valt att bryta upp och starta om våra liv. Vi valde då att flytta till en av landets största öar, nämligen Gotland senare under hösten.
            </div>
            <div className="About-content_text">  
              Under vintern har bilen gått i Moraområdet men nu ska vi dra igång verksamheten här på ön. Efter en del letande har jag hittat ytterligare en chaufför. 
            </div>
            <div className="About-content_text">    
              Jobben växer inte på träd på denna ö så jag håller nu själv på att ta taxilegitimation. När jag nu behöver ett jobb så varför leta externt när jag enkelt har ett internt. Där jag dessutom kan bestämma själv över mina arbetstider. 
            </div>
            <div className="About-content_text">    
              Detta är nu ett nytt kapitel i våra liv och det ska bli så kul att se hur företaget utvecklar sig och även följa min egen utveckling inom detta nya. 
            </div> 
            <div className="About-content_text">      
              Önska oss lycka till och bor ni här eller gör en visit på denna vackra ö, åk gärna med 4:ans Taxi eller kom och prata med oss. 
              Vi är alla väldigt trevliga och uppskattar en pratstund.
            </div>
        </div>    
      </div>
    );
  }
}

export default About;
