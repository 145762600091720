import React, { Component } from 'react';
import TaxiServices from './TaxiServices';
import Phonenumber from './Phonenumber';
import './Home.css';
import Visby from './Visby.jpg';
//import Visitkort from './visitkort.jpg';


class Home extends Component {
  render() {
    return (
      <div className="Content">
        <div className="Content-pictureBox">
          <div className="Content-picture">
            <img src={Visby} alt="Boka din resa med 4:ans taxi. Vi är pålitliga och håller hög kvalitet på våra körningar. 
             4:ans taxi utför även dina leveranser, ring oss 
            så kommer vi fram till en lösning som passar oss båda."/>
          </div>  
          <h1 className="Content-title">Vi är Gotlands nya taxibolag</h1> 
          </div>
        <Phonenumber/>     
        <div className="Home-content">

        
          
          <TaxiServices/>

        <div className='Home_content_text'>
          <span className="Home-bold"> 4:ans Taxi </span> är din pålitliga partner för smidiga och säkra resor både på Gotland och i övriga Sverige.   
          Våra förare strävar alltid efter att leverera hög kvalitet och tillförlitlighet till våra kunder. </div>
          <div className='Home_content_texts'>Oavsett om det är en kort resa runt hörnet eller en längre utflykt, kan du lita på att 4:ans Taxi tar 
          dig dit du behöver på ett bekvämt och effektivt sätt. Vi är här för att göra din resa enklare och mer bekväm varje gång. 
          Välkommen ombord på 4:ans Taxi - din partner för en bekväm och pålitlig resa!"
        </div>
        </div>
      </div>
    );
  }
}

export default Home;